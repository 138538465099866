import React from 'react'
import Parser from 'html-react-parser'

export default props => {
  // console.log(props)
  return (
    <section
      id={props.htmlId || ''}
      className="container bg-white section-full"
    >
      <div className="row justify-content-center py-3">
        <div className="col-12 mx-auto pb-4">
          <h2 className="reverse-header">
            {Parser(props.node.headline.replace('ß', '<span>ß</span>'))}
          </h2>
          {Parser(props.node.text)}
        </div>
      </div>
    </section>
  )
}
