import React from 'react'
import Parser from 'html-react-parser'
import Lightbox from 'react-images'

export default class SectionVorteile extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentImage: 0 }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
    this.gotoImage = this.gotoImage.bind(this)
  }

  openLightbox(event, index) {
    // console.log(index)
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    })
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }
  gotoImage(index) {
    this.setState({
      currentImage: index,
    })
  }

  render() {
    let imgArray = []
    this.props.model.edges.forEach(element => {
      if (element.node.images.length === 1) {
        imgArray.push({
          src: element.node.images[0].fixed.src,
          srcSet: element.node.images[0].fixed.srcSet,
          url: element.node.images[0].url,
          caption: Parser(element.node.text),
        })
      } else if (element.node.images.length > 1) {
        for (let img of element.node.images) {
          imgArray.push({
            src: img.fixed.src,
            src: img.fixed.srcSet,
            url: img.url,
            caption: Parser(element.node.text),
          })
        }
      }
    })

    // console.log(imgArray)

    const getImgArrayIndex = adresse =>
      imgArray.findIndex(x => adresse == x.url)

    return (
      <section id={this.props.htmlId || ''} className="container mb-5">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-12 col-sm-8">
            <h2 className="reverse-header">Vorteile</h2>
            <p>
              Infos zu Handling, Montage, Wirkweise und Herstellung. Die Herner
              Truck Sperre.
            </p>
          </div>
        </div>
        {this.props.model.edges.map((v, ind) => (
          <article
            key={v.node.id}
            className={`row d-flex circle-image-text mt-2 py-2 px-3 ${ind < 4 &&
              'w-500'}`}
          >
            <div className="d-flex">
              {v.node.images.map(img => (
                <div
                  key={img.id}
                  className="cropcircle mb-3 mr-4"
                  onClick={e => this.openLightbox(e, getImgArrayIndex(img.url))}
                  style={{ backgroundImage: `url('${img.fixed.src}')` }}
                />
              ))}
              {v.node.reihenfolge === 7 && (
                <a
                  href="https://www.youtube.com/watch?v=n58WSYbsVK4"
                  target="_blank"
                >
                  <div
                    className="cropcircle mr-4"
                    style={{
                      backgroundImage: `url('https://www.datocms-assets.com/8269/1546809365-ytprvw.png')`,
                    }}
                  />
                </a>
              )}
            </div>
            <main className="col">{Parser(v.node.text)}</main>
          </article>
        ))}
        <Lightbox
          images={imgArray}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
          onClickThumbnail={this.gotoImage}
          showThumbnails
        />
      </section>
    )
  }
}
