import { graphql } from 'gatsby'
import React from 'react'
import Parser from 'html-react-parser'

// import Meta from '../components/Meta.js'
import Layout from '../components/Layout.js'
import SectionHalf from '../components/SectionHalf.js'
import SectionFull from '../components/SectionFull.js'
import SectionVorteile from '../components/SectionVorteile.js'

export default ({ data, location }) => {
  const getSectionData = headline =>
    data.modelTextSektionen.edges.find(
      x => x.node.headline.slice(0, 7) === headline
    )
  // console.log(sec);
  const getestetNode = getSectionData('Geteste').node

  //Parsing table content
  const tableHTML =
    '<table style="overflow-x: scroll;" ' +
    getestetNode.text.split('<table')[1].split('</table>')[0] +
    '</table>'
  // console.log(tableHTML.replace(/(\r\n|\n|\r)/gm,""))

  return (
    <Layout location={location}>
      {/* <Meta site={get(data, 'site.meta')} /> */}
      {/* <div className="w-100 mb-5" style={{borderTop:'1px solid grey'}}></div> */}
      <SectionVorteile htmlId={'vorteile'} model={data.modelVorteile} />
      <SectionFull node={getSectionData('HTS ist').node} />
      <section id="getestet" className="container bg-white section-half">
        <div className="row justify-content-center ">
          <div className="col-12 px-4">
            <h2 className="reverse-header">{getestetNode.headline}</h2>
            <p>
              Die HTS bei CTS in M&uuml;nster einem erfolgreichen Crashtest
              unterzogen und zertifiziert worden.
            </p>
            <ul>
              <li>Crashtest IWA &ndash; 1 / 2013</li>
              <li>LKW mit 7,5 t</li>
              <li>50 km/h</li>
              <li>Anhalteweg 15 m</li>
              <li>
                LKW wurde gestoppt, war fahrunt&uuml;chtig und konnte nicht mehr
                gestartet werden
              </li>
            </ul>
            <div className="row justify-content-center mb-3">
              <div className="col-12 col-md-4 px-4 img-block-2">
                <img src="https://www.datocms-assets.com/8269/1568194094-101.jpg?auto=compress%2Cformat&fm=jpg&trim=auto&w=800" />
                <p>Crashtest IWA 14 – 1 : 2013 / N2A</p>
              </div>
              <div className="col-12 col-md-4 px-4 img-block-2">
                <img src="https://www.datocms-assets.com/8269/1568193939-102.jpg?auto=compress%2Cformat&fm=jpg&trim=auto&w=800" />
                <p>LKW nach 15 m gestoppt.</p>
              </div>
              <div className="col-12 col-md-4 px-4 img-block-2">
                <img src="https://www.datocms-assets.com/8269/1568194089-103.jpg?auto=compress%2Cformat&fm=jpg&trim=auto&w=800" />
                <p>HTS hat sich unter dem LKW verhakt.</p>
              </div>
            </div>
            <div className="row justify-content-start mb-3">
              <div className="col-12 col-md-3 px-4 img-block-2">
                <img src="https://www.datocms-assets.com/8269/1568193948-104.jpg?auto=compress%2Cformat&fm=jpg&trim=auto&w=800" />
                <p>LKW nicht mehr zu starten und nicht mehr fahrbereit.</p>
              </div>
              <div className="col-12 col-md-3 px-4 img-block-2">
                <img src="https://www.datocms-assets.com/8269/1568193950-105.jpg?auto=compress%2Cformat&fm=jpg&trim=auto&w=800" />
                <p>Die Weiterfahrt war nicht mehr möglich.</p>
              </div>
              <div className="col-12 col-md-6 px-4 img-block-2">
                <embetty-video type="youtube" video-id="PWuT0KbDie8" />
                {/* <div className="col-12 col-sm-4"></div> */}
              </div>
            </div>
            {Parser(getestetNode.text.split('<table')[0])}
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-12 col-sm-6 half-image">
            <img style={{ width: '100%' }} src={getestetNode.image.url} />
          </div>
          <div className="col-12 col-sm-6 px-4 pb-5">
            {Parser(tableHTML.replace(/(\r\n|\n|\r)/gm, ''))}
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-12 px-4 pb-5">
            {Parser(getestetNode.text.split('</table>')[1])}
          </div>
        </div>
      </section>
      {/* <SectionFull node={getSectionData('Technis').node} /> */}
      <SectionFull node={getSectionData('Absiche').node} />
      <SectionHalf
        imageLeft={true}
        htmlId={'kaufen'}
        node={getSectionData('HTS kau').node}
      />
      <section id="download" className="container bg-white section-half mb-5">
        <div className="row justify-content-start ">
          <div className="col-12 col-md-4 px-4 mt-3">
            <h2 className="reverse-header">Download Broschüre</h2>
            <a
              href="https://www.datocms-assets.com/8269/1625465068-herner-truck-sperre-flyer-drahtheftung-din-a5online-juni21.pdf"
              target="_blank"
            >
              <img
                className="w-100 h-auto"
                src="https://www.datocms-assets.com/8269/1584289068-broschuere-preview.png?auto=compress%2Cformat&fm=jpg&trim=auto&w=800"
              />
            </a>
          </div>
          <div className="col-12 col-md-4 px-4 mt-3">
            <h2 className="reverse-header">Download Zertifikat</h2>
            <a
              href="https://www.datocms-assets.com/8269/1584288785-zertifikat-hts-iwa-14-1.pdf"
              target="_blank"
            >
              <img
                className="w-100 h-auto"
                src="https://www.datocms-assets.com/8269/1584289075-zertifikat-preview.png?auto=compress%2Cformat&fm=jpg&trim=auto&w=800"
              />
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AllDataQuery {
    modelVorteile: allDatoCmsVorteile {
      edges {
        node {
          id
          reihenfolge
          images {
            id
            url
            fixed(width: 400, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFixed
            }
          }
          text
        }
      }
    }
    modelTextSektionen: allDatoCmsTextSektionen {
      edges {
        node {
          id
          headline
          image {
            id
            url
          }
          text
        }
      }
    }
  }
`
