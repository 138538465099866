import React, { Component } from 'react'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Recaptcha from 'react-recaptcha'

const API_PATH = 'https://php-bogak8r4b.now.sh/sendMail.php'
const MAIL_RECIPIENT = 'barz@herne-protect.de'
// const MAIL_RECIPIENT = 'kontakt@genius-websites.de'
const RECAPTCHA_SITE_KEY = '6LfkcIsUAAAAAKLA6oHRc-n9PzEkcvib2f7TWL4P'

export default class ContactForm extends Component {
  state = {
    // isCaptchaValid: false,
    isErrorShown: false,
    isFormValid: false,
  }

  // // Show message in console when reCaptcha plugin is loaded
  // onCaptchaLoad = () => {
  //   console.log('Captcha loaded')
  // }

  // // Update state after reCaptcha validates visitor
  // onCaptchaVerify = response => {
  //   console.log(response)
  //   this.setState({
  //     isCaptchaValid: true,
  //   })
  // }

  render() {
    return (
      <Formik
        initialValues={{ name: '', email: '', message: '' }}
        validate={values => {
          let errors = {}
          if (!values.name) errors.name = 'Feld benötigt'
          if (!values.email) errors.email = 'E-Mail-Adresse benötigt'
          if (!values.tel) errors.tel = 'Telefonnummer benötigt'
          if (!values.message) errors.message = 'Feld benötigt'
          if (!values.proof) errors.proof = 'Antwort benötigt'
          if (values.proof !== 12) errors.proof = 'Falsche Antwort'
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Ungültige E-Mail-Adresse'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            axios({
              method: 'post',
              url: `${API_PATH}`,
              data: JSON.stringify({to: MAIL_RECIPIENT, ...values}),
            })
              .then(result => {
                if (result.data.sent) {
                  console.log({
                    mailSent: result.data.sent,
                  })
                  this.setState({
                    isFormSubmitted: true,
                  })
                } else {
                  this.setState({
                    isErrorShown: true,
                  })
                }
              })
              .catch(error => {
                console.log({ error: error.message })
                this.setState({
                  isErrorShown: true,
                })
              })
              .finally(setSubmitting(false))
          }, 400)
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="form-group col-12 col-sm-6">
                <label className="control-label" htmlFor="name">
                  Name
                </label>
                <Field
                  type="text"
                  name="name"
                  className="form-control input-md"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="custom-form-error"
                />
              </div>
              <div className="form-group col-12 col-sm-6">
                <label className="control-label" htmlFor="email">
                  E-Mail
                </label>
                <Field
                  type="email"
                  name="email"
                  className="form-control input-md"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="custom-form-error"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12">
                <label className="control-label" htmlFor="tel">
                  Telefonnummer
                </label>
                <Field
                  type="text"
                  name="tel"
                  className="form-control input-md"
                />
                <ErrorMessage
                  name="tel"
                  component="div"
                  className="custom-form-error"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12">
                <label className="control-label" htmlFor="message">
                  Nachricht
                </label>
                <Field
                  component="textarea"
                  name="message"
                  placeholder="Text eingeben..."
                  className="form-control"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="custom-form-error"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-8 col-sm-4">
                <label className="control-label my-label" htmlFor="proof">
                  Sicherheitsfrage: Was ist 2 + 10?
                </label>
              </div>
              <div className="form-group col-3">
                <Field
                  type="number"
                  name="proof"
                  className="form-control"
                />
                <ErrorMessage
                  name="proof"
                  component="div"
                  className="custom-form-error"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12">
                {/* <Recaptcha
                  onloadCallback={this.onCaptchaLoad}
                  sitekey={`${RECAPTCHA_SITE_KEY}`}
                  // render="explicit"
                  verifyCallback={this.onCaptchaVerify}
                /> */}
                <button
                  type="submit"
                  className="btn btn-hts"
                  // disabled={isSubmitting || !this.state.isCaptchaValid}
                  disabled={isSubmitting || !values.proof || Object.keys(errors).length > 0}
                >
                  ABSENDEN
                </button>
                {this.state.isFormSubmitted && (
                  <p
                    style={{ color: 'green !important' }}
                    className="custom-form-success"
                  >
                    Ihre Nachricht wurde erfolgreich gesendet. Vielen Dank!
                  </p>
                )}

                {this.state.isErrorShown && (
                  <p className="custom-form-error">
                    Die Nachricht konnte nicht gesendet werden. Bitte versuchen
                    Sie es noch einmal zu einem späteren Zeitpunkt oder
                    informieren Sie den Administrator der Webseite.
                  </p>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
}
