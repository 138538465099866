import React from 'react'
import Parser from 'html-react-parser'

import Form from './Form.js'

export default props => {
  // let directionClass = 'flex-row'
  let directionClass = props.imageLeft ? 'flex-row' : 'flex-row-reverse'
  // console.log(props.node)
  return (
    <section
      id={props.htmlId || ''}
      className="container bg-white section-half"
    >
      <div className={'row justify-content-center ' + directionClass}>
        <div
          className="col-12 col-sm-4 col-md-6 half-image"
          // style={{ backgroundImage: 'url(' + props.node.image.url + ')' }}
        >
          <img style={{ width: '100%' }} src={props.node.image.url} />
        </div>
        <div className="col-12 col-sm-8 col-md-6 px-4 pb-5 half-content">
          <h2 className="reverse-header">{props.node.headline}</h2>
          {Parser(props.node.text)}
          {props.node.headline.slice(0, 7) === 'HTS kau' && <Form />}
        </div>
      </div>
    </section>
  )
}
